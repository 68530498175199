/* eslint-disable */
var InteractionTypeImpl = InteractionType.extend({

  init: function(blueConicClient, context) {
    this.blueConicClient = blueConicClient;
    this.context = context ? context : null;

    this.OBJECTIVE_ID = "didomi_purpose_";

  },

  onPrepare: function() {
    this.prepare = true;
    this._pushDidomiOnReady()
  },

  onLoad: function() {
    this.prepare = false;
    this._subscribeOnConsentChanged();
    if (this._isConsentManagementAvailable()) {
      this._handleConsent();
    } else {
      this._pushDidomiOnReady();
    }
  },

  _pushDidomiOnReady: function() {
    var me = this;

    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function(Didomi) {
      me.didomiIsReady = true;
      // Call other functions on the SDK
      if (me._isConsentManagementAvailable()) {
        me._handleConsent();
      }
    });
  },

  /**
   * Handler consent
   */
  _handleConsent: function() {
    if (!this._isConsentManagementAvailable() || !this.blueConicClient) {
      return;
    }

    var profile = blueConicClient.profile.getProfile();
    
    var purposes = window.Didomi.getUserConsentStatusForAll().purposes;

    this._handleResponse(purposes, {
      blueConicClient: blueConicClient,
      profile: profile
    });

  },

  /**
   * Check if Consent Management framework is available
   */
  _isConsentManagementAvailable: function() {
    return window.Didomi && this.didomiIsReady;
  },

  /**
   * Handle the response for Didomi consent
   */
  _handleResponse: function(purposes, options) {
    options = options || {};
    var blueConicClient  = options.blueConicClient;
    var profile = options.profile || blueConicClient.profile.getProfile();

    var consentedObjectives = [];
    var refusedObjectives = [];

    if (purposes)  {
      for (var i = 0; i < purposes.enabled.length; i++) {
        consentedObjectives.push(this.OBJECTIVE_ID + purposes.enabled[i]);
      }
      for (var i = 0; i < purposes.disabled.length; i++) {
        refusedObjectives.push(this.OBJECTIVE_ID + purposes.disabled[i]);
      }
    }
    
    this.updateObjectives(profile, consentedObjectives, refusedObjectives);

    if (!this.prepare) {
      blueConicClient.profile.updateProfile();
    }
  },

  updateObjectives: function(profile, consentResult, refusedResult) {
    if (!this.prepare) {
      // in onload
      this.addConsentedObjectives(profile, consentResult);
      this.addRefusedObjectives(profile, refusedResult);
      return;
    } else {
      if (consentResult.length === 0) {
        profile.setConsentedObjectives([]);
      } else {
        this.addConsentedObjectives(profile, consentResult);
      }

      if (refusedResult.length === 0) {
        profile.setRefusedObjectives([]);
      } else if(refusedResult.length > 0) {
        this.addRefusedObjectives(profile, refusedResult);
      }
    }
  },

  addConsentedObjectives: function(profile, objectives) {
    this.addObjectives(profile, objectives, true);
  },

  addRefusedObjectives: function(profile, objectives) {
    this.addObjectives(profile, objectives, false);
  },

  addObjectives: function(profile, objectives, isConsented) {
    for(var i = 0; i < objectives.length; i++) {
      isConsented ?
        profile.addConsentedObjective(objectives[i]) :
        profile.addRefusedObjective(objectives[i]);
    }
  },

  _subscribeOnConsentChanged: function() {    
    if (window.didomiEventListeners) {
      var me = this;
      window.didomiEventListeners.push({
        event: 'consent.changed',
        listener: function (context) {
          me._handleConsent();
        }
      });
    }
  }
});